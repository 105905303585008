/***********************************************************************/
/* Styles relatifs aux transitions des critères simples de recherche */
/***********************************************************************/
.summary-result {
    position: absolute;
    width: 0px;
    height: 0px;
    margin-top: 10px;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}
    /*Affichage de la zone de recherche simple*/
    .summary-result.show {
        position: relative;
        width: 100%;
        height: 100%;
        opacity: 1;
        visibility: visible;
    }
/***********************************************************************/

/***********************************************************************/
/* Styles relatifs aux transitions des critères étendus de recherche */
/***********************************************************************/
.table-result {
    position: absolute;
    width: 0px;
    height: 0px;
    margin-top: 10px;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}
    /*Affichage de la zone de recherche étendu*/
    .table-result.show {
        position: relative;
        width: 100%;
        height: 100%;
        opacity: 1;
        display: normal;
        visibility: visible;
    }
/***********************************************************************/

/***********************************************************************/
/* Summary result  */
/***********************************************************************/
.blockLeftUp {
    height: 30px;
}

    .blockLeftUp:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%;
    }

    .blockLeftUpInner {
        margin-right: 15px;
        display: inline-block;
        vertical-align: middle;
    }

/***********************************************************************/
/* Div de heade de recherche */
/***********************************************************************/
.summaryResultItemContainer {
}

.blockHeaderResult {
    height: 40px;
}

    .blockHeaderResult:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%;
    }

.blockHeaderResultItem {
    display: inline-block;
    vertical-align: middle;
    padding: 3px 5px;
    color: black;
}
/***********************************************************************/

.industryCodeImage {
    margin-top: 20px;
}

.QuickViewItemOk {
    color: green;
}

.QuickViewItemKo {
    color: red;
}

.QuickViewSummaryTitle {
    color: black;
}

.QuickViewSummaryCard {
    background-image: linear-gradient(to right, #dedce0, #d5d3d7, #cccacf, #c4c1c6, #bbb8be);
}

.QuickViewSummaryCardAlternate {
    background-image: linear-gradient(to right, #dedce0, #c3c1c5, #a9a7ac, #908e92, #77757a);
}
.ReactTable {
    overflow: scroll;
    position: relative;
    height: 700px;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 500;
    font-size: 12px;
}