/***********************************************************************/
/* Styles relatifs aux transitions des critères simples de recherche */
/***********************************************************************/
.simple-search {
    position: absolute;
    width: 0px;
    height: 0px;
    margin-top: 10px;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}
    /*Affichage de la zone de recherche simple*/
    .simple-search.show {
        position: relative;
        width: 100%;
        height: 100%;
        opacity: 1;
        visibility: visible;
    }
/***********************************************************************/

/***********************************************************************/
/* Styles relatifs aux transitions des critères étendus de recherche */
/***********************************************************************/
.extended-search {
    position: absolute;
    width: 0px;
    height: 0px;
    margin-top: 10px;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}
    /*Affichage de la zone de recherche étendu*/
    .extended-search.show {
        position: relative;
        width: 100%;
        height: 100%;
        opacity: 1;
        display: normal;
        visibility: visible;
    }
/***********************************************************************/

/***********************************************************************/
/* Styles relatifs aux transitions des critères complexes de recherche */
/***********************************************************************/
.complex-search {
    position: absolute;
    width: 0px;
    height: 0px;
    margin-top: 10px;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}
    /*Affichage de la zone de recherche complexe*/
    .complex-search.show {
        position: relative;
        opacity: 1;
        visibility: visible;
    }
/***********************************************************************/



/***********************************************************************/
/* Div de heade de recherche */
/***********************************************************************/
.blockHeaderSearch {
    height: 40px;
}

    .blockHeaderSearch.Reduce {
    margin-bottom: 15px;
    }

    .blockHeaderSearch:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%;
    }

.blockHeaderSearchItem {
    display: inline-block;
    vertical-align: middle;
    padding: 3px 5px;
    color: black;
}

.blockHeaderTitle {
    color: black;
    vertical-align: middle;
}
/***********************************************************************/


/***********************************************************************/
/* Boutons de recherche */
/***********************************************************************/
.leftButtonSearch {
    padding-top: 1px;
    padding-right: 1px;
    padding-bottom: 1px;
    padding-left: 3px;
    background-color: lightgray;
}

    .leftButtonSearch.Selected {
        background-color: darkgray;
    }

.middleButtonSearch {
    padding-top: 1px;
    padding-right: 1px;
    padding-bottom: 1px;
    padding-left: 1px;
    background-color: lightgray;
}

    .middleButtonSearch.Selected {
        background-color: darkgray;
    }

.rightButtonSearch {
    padding-top: 1px;
    padding-right: 3px;
    padding-bottom: 1px;
    padding-left: 1px;
    background-color: lightgray;
}

    .rightButtonSearch.Selected {
        background-color: lightgray;
    }
/***********************************************************************/

