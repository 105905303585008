.rwt__tabs[data-rwt-vertical="true"] {
    display: flex;
    margin-left: 1rem;
    margin-top: 2rem;
}

.rwt__tablist:not([aria-orientation="vertical"]) {
    border-bottom: 1px solid #ddd;
}

.rwt__tablist[aria-orientation="vertical"] {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 0;
    border-right: 1px solid #ddd;
    margin-right: 1rem;
}

.rwt__tab {
    background: transparent;
    border: 0;
    font-family: inherit;
    font-size: inherit;
    padding: 1.8rem 2rem;
    transition: background 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
}

    .rwt__tab[aria-selected="false"]:hover,
    .rwt__tab:focus {
        outline: 0;
        background-color: #f4f4f4;
        background-color: rgba(0,0,0,0.05);
    }

    .rwt__tab[aria-selected="true"] {
        position: relative;
        background-color: #f4f4f4;
        background-color: rgba(0,0,0,0.05);
    }

        .rwt__tab[aria-selected="true"]:after {
            content: '';
            position: absolute;
        }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
    bottom: -1px;
    left: 0;
    width: 100%;
    border-bottom: 3px solid #00d8ff;
}

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
    right: -1px;
    top: 0;
    height: 100%;
    border-right: 3px solid #e91e63;
}

.rwt__tabpanel{
    margin-left: 1rem;
    width: 100%;
}
